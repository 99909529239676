import styled from "@emotion/styled"

export const SitemapContent = styled.div`
  grid-column: center-start / center-end;
  grid-row: 1 / -1;
  gap: 6rem;
  padding-block: 8rem;
  display: flex;
  flex-direction: column;
  margin-block-start: 8rem;

  header {
    --underline: 8px;
    padding-block-end: 1rem;
    border-block-end: 1px solid rgb(255 0 0 / 0.15);

    h2 {
      display: inline-block;
      position: relative;
    }

    h2::after {
      display: inline-block;
      content: "";
      height: var(--underline);
      position: absolute;
      inset: calc(150% - var(--underline)) 0 0 2rem;
      /* inset-inline-start: 2rem; */
      background-color: rgb(255 0 0 / 0.25);
    }
  }
`

export const SitemapSection = styled.section`
  grid-column: center-start / center-end;
  grid-row: 1 / -1;

  display: flex;
  flex-direction: ${props => (props.column ? "column" : "row")};
  gap: 2rem;

  &.sub-section-grid {
    flex-wrap: wrap;
    header {
      flex-basis: 100%;
    }
  }
`

export const SitemapList = styled.ul`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(26rem, 1fr));
  gap: 4rem;
  margin-inline-start: 2rem;

  h3 {
    margin-block-end: 0.85rem;
  }

  li > ul {
    margin-inline-start: 1rem;

    li {
      padding-block-end: 0.5em;
    }
  }
`

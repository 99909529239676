import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
// import { GatsbyImage } from "gatsby-plugin-image"

import Seo from "../components/seo"
import Breadcrumb from "../components/Breadcrumb"
import infolinks from "../constants/info-links"
// import { linksQuery } from "../components/Navigation/index"

import { CategoryMain } from "../styles/Layout"
import {
  SitemapContent,
  SitemapList,
  SitemapSection,
} from "../components/Sitemap/SitemapElements"
import Newsletter from "../components/Newsletter"

const sitemapQuery = graphql`
  query SitemapLinks {
    category: allContentfulCategory(sort: { fields: order, order: ASC }) {
      nodes {
        id: contentful_id
        title
        slug
        subCategories {
          subCatId: id
          title
          slug
          article {
            slug
          }
        }
      }
    }
  }
`

const Sitemap = () => {
  const data = useStaticQuery(sitemapQuery)
  const links = data.category.nodes

  return (
    <>
      <Seo
        title="Sitemap"
        description="Browse through our sitemap for all topics and site links"
      />
      <CategoryMain id="main-content">
        <Breadcrumb
          className="sm-breadcrumb-height"
          categoryTitle="Sitemap"
          categoryLink="/sitemap"
        />
        {/* <GatsbyImage 
          as="figure"
          image=""
          alt=""
          style={{
              height: "60vh",
              gridColumn: "full-start/full-end",
              // gridRow: "4/5",
            }}
        /> */}

        <SitemapContent>
          <SitemapSection column="column">
            <header>
              <h2>
                {/* {">"} <span>Topics/Categories</span> */}
                {">"} <span>All Categories</span>
              </h2>
            </header>
            <SitemapList>
              {links.map(link => {
                return (
                  <li key={link.id}>
                    <h3>
                      <Link to={`/${link.slug}/`}>{link.title}</Link>
                    </h3>
                    {link?.subCategories.length !== 0 && (
                      <ul>
                        {link?.subCategories.map(subCat => {
                          return (
                            <li key={subCat.subCatId}>
                              <Link to={`/${link.slug}/${subCat.slug}`}>
                                {subCat.title}
                              </Link>
                            </li>
                          )
                        })}
                      </ul>
                    )}
                  </li>
                )
              })}
            </SitemapList>
          </SitemapSection>

          <SitemapSection className="sub-section-grid">
            <header>
              <h2>{">"} Site Links</h2>
            </header>
            <SitemapList>
              <li>
                <h3>Info Links</h3>
                <ul>
                  {infolinks.slice(0, -1).map(infoLink => {
                    return (
                      <li key={infoLink.id}>
                        <Link to={infoLink.path}>{infoLink.label}</Link>
                      </li>
                    )
                  })}
                </ul>
              </li>
            </SitemapList>
          </SitemapSection>
        </SitemapContent>

        <Newsletter />
      </CategoryMain>
    </>
  )
}

export default Sitemap
